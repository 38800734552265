import React, { useState } from "react";
import Table from '../../components/Table/Table';
import TableHeader from '../../components/Table/TableHeader';
import '../../assets/css/ProspectDetails.css';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Buttons from "../../components/Form/Button/Button";
import ChipStatus from "../../components/Status/ChipStatus";
import DraggableDialog from '../../components/Dialog/Dialog';


const ProspectsDetails = () => {


  //======= Approve Dialog ===== //
  const [openDeleteinvitationDialog, setOpenDeleteinvitationDialog] = useState(false);
  const handleOpenDeleteinvitationDialog = () => {
    setOpenDeleteinvitationDialog(true);
  };
  const handleCloseDeleteinvitationDialog = () => {
    setOpenDeleteinvitationDialog(false)
  };
  //======= Approve Dialog ===== //


  //===== Table Data Started === //

  const onClick = () => {
    alert('onclick working fine!');
  };


  const rows = [
    {
      cell: [
        { date: 'Invite sent to qadhia2002@gmail.com by [Admin FullName]', },
        { date: '31-Dec-2023, 12:00 AM', TextAlign: "right" },
      ],
    },

    {
      cell: [
        { date: 'Submitted become a teacher form', },
        { date: '31-Dec-2023, 12:00 AM', TextAlign: "right" },
      ],
    },

  ];

  //===== Table Data End === //



  return <div>

    <div className="ProspectDetails">
      <div className="ProspectDetailsLeft">
        <Avatar alt="Omar Suleiman" src="/static/images/avatar/1.jpg" />
        <div className="ProspectDetailsText">
          <h1>Omar Suleiman</h1>
          <p>Initially invited by [Admin FullName] on 31-Aug-2023</p>
        </div>
      </div>
      <div className="ProspectDetailsRight">
        <ChipStatus
          StatusLabel="Invitation Pending"
          StatusColor="#7D8597"
          StatusLine={true}
        />
      </div>
    </div>

    <div className="ProspectPersonainfo">
      <li>
        <p>Account type</p>
        <h2>Organization</h2>
      </li>
      <li>
        <p>Email</p>
        <h2>realomersuleiman@gmail.com</h2>
      </li>
      <li>
        <p>Phone</p>
        <h2>+1 (123) 123-1234</h2>
      </li>
      <li>
        <p>Stream</p>
        <h2>Manual</h2>
      </li>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Buttons
            label="Resend invitation"
            buttonColor="#0450E1"
            border="#0450E1 1px solid"
            color="#fff"
            width="100%"
            height="40px"
            fontSize="14px"
          //onClick={ }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Buttons
            label="Delete Invitation"
            buttonColor="#fff"
            border="#E8EAED 1px solid"
            color="#001233"
            width="100%"
            height="40px"
            fontSize="14px"
            onClick={handleOpenDeleteinvitationDialog}
          />
        </Grid>
      </Grid>


    </div>

    <div className="ActivityTaleContainer">
      <TableHeader
        TableTitle="Activity"
      />
      <Table
        rows={rows}
        footer={true}
      />
    </div>


    <DraggableDialog
      openDialog={openDeleteinvitationDialog}
      handleCloseDialog={handleCloseDeleteinvitationDialog}
      title="Delete invitation"
      body={<div>Are you sure you want to delete this invitation?<br/> Invitee will still be able to accept the invite using previous link.</div>}
      ModalFooter={true}
      actionButton="Delete"
    />

  </div>
};

export default ProspectsDetails;
