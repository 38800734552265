import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deepPurple } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";

// Menu Icons
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Badge, Button, Menu, MenuItem } from "@mui/material";
import "./Header.css";

function Header(props) {
  const takbirLogo =
    "https://cdn.mytakbir.com/static-assets/logos/takbir-lms-learning-management-system-muslim-logo-3x.png";
  const takbirUrl = "https://mytakbir.com/";

  // Logout menue unauthorized
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Logout menue unauthorized

  // ====== Header Scroll Start ======//
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef(null);

  // handle scroll event
  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  // add/remove scroll event listener
  useEffect(() => {
    var header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height);
    };

    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);
  // ====== Header Scroll End ======//

  return (
    <div className="" style={{ marginTop: sticky.offset }}>
      <div
        id="sticky-header"
        className={`navbar${sticky.isSticky ? " sticky" : ""}`}
        ref={headerRef}
      >
        {props.AlertBody}

        <div className="HeaderContainer">
          <div className="Header">
            <div className="HdrLeftCol">
              <div className="IconMenu">{props.IconMenu}</div>
              {takbirLogo?.length > 0 && (
                <Link
                  className="Branding"
                  to={takbirUrl?.length > 0 ? takbirUrl : "#"}
                >
                  <img
                    src="https://web-assets.deenschool.io/assets/logos/deen-school-logos-2x/deenschool-islamic-school-management-logo-cl.png"
                    alt="website logo"
                  />
                </Link>
              )}
            </div>

            <div className="HdrRightCol">
              <div className="DesktopView">{props.ButtonBody}</div>

              <Button variant="text" className="NotificationButton">
                {/* <div className="NotificationCount"></div> */}
                <Badge color="secondary" badgeContent="">
                  <NotificationsRoundedIcon sx={{ color: "#0B132B" }} />
                </Badge>
              </Button>

              {/* <Avatar onClick={handleClick}>{Array.from(userData.firstName)[0]}</Avatar> */}

              <div
                className="ProfileSection"
                role="button"
                onClick={handleClick}
              >
                <Avatar
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={{ bgcolor: deepPurple[300] }}
                >
                  M
                </Avatar>
                <h1>Parsa</h1>
                <KeyboardArrowDownRoundedIcon sx={{ color: "#77767A" }} />
              </div>
              {/* :
              <Avatar
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}

                sx={{ bgcolor: deepPurple[300] }}
              >S</Avatar> */}

              <div className="">
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="DropdownMenu"
                >
                  {/* <MenuItem onClick={handleClose}>
                    <div className="ProfileSection">
                      {userData.image ?
                        <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} src={userData.image ? userData.image : ""} ></Avatar>
                        :
                        <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} > {userData.firstName ? Array.from(userData.firstName)[0] : ""} </Avatar>
                      }
                      <div><h1>{userData.firstName} {userData.lastName}</h1><p><a href="#">{userData.email}</a></p></div>
                    </div>
                  </MenuItem> */}

                  <MenuItem onClick={handleClose}>
                    <div className="ProfileSection">
                      <Avatar
                        sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
                      >
                        M
                      </Avatar>
                      <div>
                        <h1>Parsa</h1>
                        <p>
                          <a href="/">email@email.com</a>
                        </p>
                      </div>
                    </div>
                  </MenuItem>

                  {/* <span className="divider"></span> */}
                  {/* <MenuItem onClick={handleClose}><HomeRoundedIcon /> Home</MenuItem> */}
                  {/* <MenuItem onClick={handleClose}><MenuBookRoundedIcon /> Courses</MenuItem> */}
                  {/* <span className="divider"></span> */}
                  {/* <MenuItem onClick={handleClose}><NotificationsRoundedIcon /> Alerts</MenuItem>
                <MenuItem onClick={handleClose}><QuestionAnswerRoundedIcon /> Messages</MenuItem> */}
                  {/* <MenuItem onClick={handleClose}><InsertInvitationRoundedIcon /> Calendar</MenuItem> */}
                  {/* <span className="divider"></span> */}
                  {/* <MenuItem onClick={handleClose}><EmojiEventsRoundedIcon /> Certificates</MenuItem> */}
                  {/* <MenuItem onClick={handleClose}><SettingsRoundedIcon /> Settings</MenuItem> */}

                  <span className="divider"></span>
                  <MenuItem>
                    <LogoutIcon /> Log Out
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          {props.SidebarBody}
        </div>
      </div>
    </div>
  );
}

export default Header;
