import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import SelectField from '../../components/Form/SelectField/SelectField';
import TextField from '../../components/Form/TextField/TextField';
import PhoneInputField from '../../components/Form/PhoneInputField/PhoneInputField';





const NewAccount = () => {

  //=== Select Field ==//
  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "Twenty",
      value: "Twenty",
    },

    {
      label: "Twentydd",
      value: "Twentyd",
    },

  ]

  //=== Phone Field ==//
  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });



  return <div>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12}>
        <SelectField
          label="Account type"
          FieldLabel={true}
          options={options}
          handleChange={handleChanges}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <TextField
          label="First name"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <TextField
          label="Last name"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <TextField
          label="Email address*"
          helperText="An invite will be sent to this email and the owner will use this email to login."
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <PhoneInputField
          phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
          country={'us'}
          value={phone.number}
          countryCodeEditable={false}
          onChange={(phone, country) => console.log("onChange", phone, country)}
          onBlur={() => console.log("onBlur")}
          onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
          variant="outlined"
          // onlyCountries={["us", "ca", "gb", "my"]}
          containerStyle={{}}
          buttonStyle={{}}
          inputStyle={{
            marginLeft: "0px",
            height: "40px",
            width: "100%",
            fontFamily: "Roboto-Regular",
            fontSize: "16px",
          }}
          containerClass={{}}
          errorStyles={{ marginBottom: "5px" }}
          autoFormat={true}
          disableDropdown={false}
          disabled={false}
          disableCountryCode={false}
          enableSearch={false}
          disableSearchIcon={false}
          placeholder={""}
          inputProps={{}}
          specialLabel={'Primary Phone'}
          // preferredCountries={[]}
          // excludeCountries={[]}
          labelFixed="Field label"
          LabelStatus={true}
          //error="error"
        />
      </Grid>

    </Grid>
  </div>
};

export default NewAccount;
