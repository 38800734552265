import React, {useState} from "react";
import Table from '../../components/Table/Table';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';
import SideDrawer from '../../components/SideDrawer/SideDrawer';

import ProspectsDetails from './ProspectsDetails';



const Prospects = () => {


   //==== New Teaching Account Drawer Start ===//
   const [openDetails, setOpenDetails] = useState(false);
   const handleDrawerOpenDetails = () => {
     setOpenDetails(true);
   };
   const handleDrawerCloseDetails = () => {
     setOpenDetails(false);
   };
   //==== New Teaching Account Drawer End ===//


  //===== Table Data Started === //

  const onClick = () => {
    alert('onclick working fine!');
  };

  const headCells = [
    { label: 'Name', width: '20%', textAlign: 'left', },
    { label: 'Type', width: '20%', textAlign: 'left', },
    { label: 'Email', width: '20%', textAlign: 'left', },
    { label: 'Stream', width: '10%', textAlign: 'left', },
    { label: 'Invited', width: '10%', textAlign: 'left', },
    { label: 'Status', width: '20%', textAlign: 'left', },
    { label: '', width: '10%', textAlign: 'left', },
  ];

  const rows = [
    {
      cell: [
        { linkText: 'Omar Suleiman', linkTextStatus: true, link: '#', },
        { text: 'Hikmah Academy of California', iconStart: <DomainRoundedIcon />, IconStart: true, },
        { linkText: 'realomersuleiman@gmail.com', linkTextStatus: true, link: '#', },
        { text: 'Manual', },
        { text: '21-Jan-2024', },
        { Status: true, background: '#E1F2E8', color: '#0B9444', linebackground: "#41AB37", statusLine: true, label: 'Accepted', },
        { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
      ],

      //======= Action Date Start===== //
      action: [
        { label: 'View', onClick: handleDrawerOpenDetails },
        { label: 'Edit', onClick: onClick },
        { label: 'Delete', onClick: onClick },
      ],
      //======= Action Date End===== //
    },

    {
      cell: [
        { linkText: 'Omar Suleiman', linkTextStatus: true, link: '#', },
        { text: 'Hikmah Academy of California', iconStart: <DomainRoundedIcon />, IconStart: true, },
        { linkText: 'realomersuleiman@gmail.com', linkTextStatus: true, link: '#', },
        { text: 'Manual', },
        { text: '21-Jan-2024', },
        { Status: true, background: '#EEEDFF', color: '#7266FB', linebackground: "#7266FB", statusLine: true, label: 'New', },
        { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
      ],

      //======= Action Date Start===== //
      action: [
        { label: 'View', onClick: handleDrawerOpenDetails },
        { label: 'Edit', onClick: onClick },
        { label: 'Delete', onClick: onClick },
      ],
      //======= Action Date End===== //
    },
    {
      cell: [
        { linkText: 'Omar Suleiman', linkTextStatus: true, link: '#', },
        { text: 'Hikmah Academy of California', iconStart: <DomainRoundedIcon />, IconStart: true, },
        { linkText: 'realomersuleiman@gmail.com', linkTextStatus: true, link: '#', },
        { text: 'Manual', },
        { text: '21-Jan-2024', },
        { Status: true, background: '#E8EAED', color: '#5C677D', linebackground: "#5C677D ", statusLine: true, label: 'Invitation Pending', },
        { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
      ],

      //======= Action Date Start===== //
      action: [
        { label: 'View', onClick: handleDrawerOpenDetails },
        { label: 'Edit', onClick: onClick },
        { label: 'Delete', onClick: onClick },
      ],
      //======= Action Date End===== //
    },
  ];

  //===== Table Data End === //



  return <div>
    <Table
      headCells={headCells}
      rows={rows}
      footer={true}
      tableHeader={true}
    />

    <SideDrawer
      title="Prospect Details"
      ButtonLabel="Invite"
      cancelButtonLabel="Cancel"
      DeleteButton={true}
      sideOpen="right"
      open={openDetails}
      handleDrawerClose={handleDrawerCloseDetails}
      FooterWithoutTab={false}
      body={
        <p><ProspectsDetails /></p>
      }
    />

  </div>
};

export default Prospects;
