import React, {useState} from "react";

import TableHeader from '../../components/Table/TableHeader';
import TableSubHeader from '../../components/Table/TableSubHeader';
import TeachingAccounts from './TeachingAccounts';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import NewAccount from './NewAccount';
import Prospects from './Prospects';




const Accounts = () => {

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const tabLabel = [
    {
      label: "Teaching Accounts",
    },
    {
      label: "Prospects",
    },

  ]

  const tabPanel = [
    {
      id: 0,
      panel: <TeachingAccounts />
    },
    {
      id: 1,
      panel: <Prospects />,
    },
  ]

  //==== New Teaching Account Drawer Start ===//
  const [openNew, setOpenNew] = useState(false);
  const handleDrawerOpenNew = () => {
    setOpenNew(true);
  };
  const handleDrawerCloseNew = () => {
    setOpenNew(false);
  };
  //==== New Teaching Account Drawer End ===//

  return <div>

    <TableHeader
      TableTitle="Accounts"
      AddButton={true}
      AddButtonLabel="Invite new"  
      AddButtonOnClick={handleDrawerOpenNew}
    />

    <TableSubHeader
      Tabs={true}
      tabLabel={tabLabel}
      tabPanel={tabPanel}
      TabValue={value}
      TabHandleChange={handleChange}
      Filter={true}
      //FilterOnClick={FilterOnClick}

      Export={true}
      //ExportOnClick={ExportOnClick}

      //== Search Code == //
      Search={true}
      SearchPlaceholder="Search..."
      // SearchValue={searchTerm}
      //SearchOnChange={handleSearchText}
      //SearchOnclick={SearchOnclick}
      //SearchOnMouseDown={SearchOnMouseDown}
      //handleSearch={handleSearch}
      Border={true}
    />

    <SideDrawer
      title="New Teaching Account"
      ButtonLabel="Invite"
      cancelButtonLabel="Cancel"
      DeleteButton={true}
      sideOpen="right"
      open={openNew}
      handleDrawerClose={handleDrawerCloseNew}
      FooterWithoutTab={true}
      body={
        <p><NewAccount /></p>
      }
    />

  </div>
};

export default Accounts;
