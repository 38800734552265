import React, { useEffect, useState } from "react";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Status from '../../components/Status/Status';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import DraggableDialog from '../../components/Dialog/Dialog';








const TeachingProfile = () => {


  //======= Approve Dialog ===== //
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const handleOpenApproveDialog = () => {
    setOpenApproveDialog(true);
  };
  const handleCloseApproveDialog = () => {
    setOpenApproveDialog(false)
  };
  //======= Approve Dialog ===== //

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#fff",
    },

  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "#fff",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));



  const statusDropdownData = [
    { value: 3, statuscolor: "#3CC480", select: "Active" },
    { value: 2, statuscolor: "#FF9800", select: "Status" },
    { value: 1, statuscolor: "#7266FB", select: "Active" },
    { value: 4, statuscolor: "#818E94", select: "On hold" },
    { value: 5, statuscolor: "#C44058", select: "Canceled" },
  ];


  const statustData = [
    {
      Label: "Stream",
      SubLabel: "Manual",
      Color: "#7D8597",
      line: false,
    },

    {
      Label: "Created",
      SubLabel: "21-Jan-2024",
      Color: "#7D8597",
      line: false,
    },
  ];


  return <div>

    <div className="ApproveAlertModal">
      <div className="ApproveAlertModalLeft"><WarningRoundedIcon /> Teaching account access disabled until approved.</div>
      <div className="ApproveButton"><Button variant="text" onClick={handleOpenApproveDialog}>Approve Now</Button></div>

    </div>

    <Status
      MainTitle={true}
      Title="International University of Bangladesh"
      BackLinkLabel="Back"

      StatusDropdownStatus={true}
      statusDropdownData={statusDropdownData}



      StaticStatus={true}
      staticStatustData={statustData}
      defaultValue={3}
    />

    <div className="AccountDetailTable">
      <div className="OddTableContainer">
        <div className="AccountDetailTableTitle">Account details</div>
        <TableContainer>
          <Table>

            <TableBody>

              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel" width="30%">Account ID</StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">123456</StyledTableCell>
                <StyledTableCell align="left" ></StyledTableCell>
              </StyledTableRow>

              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">Account Type</StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">Organization</StyledTableCell>
                <StyledTableCell align="right" className="AccountCopyButton alignRight"><Button variant="text"><ContentCopyIcon /> Copy</Button></StyledTableCell>
              </StyledTableRow>

              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">Owner</StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  <p><a href="#">Zakir Naik</a>  <span className="VerifyCheckIcon"><CheckCircleRoundedIcon /></span></p>
                  <p>verygoodquestion@gmail.com</p>
                  <p>+1 (123) 123-1234</p>
                </StyledTableCell>
                <StyledTableCell align="left" ></StyledTableCell>
              </StyledTableRow>

              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">TA Page URL</StyledTableCell>
                <StyledTableCell align="left" className="TableInfo"><a href="#" >https://dciub.mytakbir.com</a></StyledTableCell>
                <StyledTableCell align="right" className="AccountCopyButton alignRight"><Button variant="text"><ContentCopyIcon /> Copy</Button></StyledTableCell>
              </StyledTableRow>



            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>


    <div className="AccountDetailTable">
      <div className="OddTableContainer">
        <div className="AccountDetailTableTitle">Organization details</div>
        <TableContainer>
          <Table>

            <TableBody>

              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel" width="30%">Organization Name</StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">Zakir Naik Foundation</StyledTableCell>
              </StyledTableRow>

              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">Category</StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">Religious and Social Organization</StyledTableCell>
              </StyledTableRow>

              <StyledTableRow >
                <StyledTableCell align="left" className="TableLabel">Address</StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  101 Collin Street<br />
                  New York, NY 10014<br />
                  United States
                </StyledTableCell>
              </StyledTableRow>


            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>


    <DraggableDialog
      openDialog={openApproveDialog}
      handleCloseDialog={handleCloseApproveDialog}
      title="Approve teaching account"
      body={<div>Are you sure you want to approve <strong>[International University of Bangladesh]</strong></div>}
      ModalFooter={true}
      actionButton="Approve"
    />


  </div>
};

export default TeachingProfile;
