import React from "react";

import TableHeader from '../../components/Table/TableHeader';
import TableSubHeader from '../../components/Table/TableSubHeader';
import Table from '../../components/Table/Table';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';





const TeachingAccounts = () => {

  //===== Table Data Started === //

  const onClick = () => {
    alert('onclick working fine!');
  };

  const headCells = [
    { label: 'TA Name', width: '25%', textAlign: 'left', },
    { label: 'Type', width: '18%', textAlign: 'left', },
    { label: 'Owner', width: '15%', textAlign: 'left', },
    { label: 'Stream', width: '10%', textAlign: 'left', },
    { label: 'Created', width: '10%', textAlign: 'left', },
    { label: 'TA Status', width: '10%', textAlign: 'left', },
    { label: '', width: '10%', textAlign: 'left', },
  ];

  const rows = [
    {
      cell: [
        { linkText: 'Hikmah Academy of California', linkTextStatus: true, link: '#', },
        { text: 'Hikmah Academy of California', iconStart: <DomainRoundedIcon />, IconStart: true, },
        { linkText: 'Omar Suleiman', linkTextStatus: true, link: '#', verifiedIcon:true, },
        { text: 'Online', },
        { text: '21-Jan-2024', },
        { Status: true, background: '#E1F2E8', color: '#0B9444', linebackground: "#41AB37", statusLine: true, label: 'Active', },
        { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
      ],

      //======= Action Date Start===== //
      action: [
        { label: 'View', link:"/accounts-details"},
        { label: 'Edit', onClick: onClick },
        { label: 'Delete', onClick: onClick },
      ],
      //======= Action Date End===== //
    },

    {
      cell: [
        { linkText: 'Hikmah Academy of California', linkTextStatus: true, link: '#', },
        { text: 'Hikmah Academy of California', iconStart: <DomainRoundedIcon />, IconStart: true, },
        { linkText: 'Zakir Naik', linkTextStatus: true, link: '#', verifiedIcon:true, },
        { text: 'Online', },
        { text: '21-Jan-2024', },
        { Status: true, background: '#FFF5E6', color: '#FFAE35', linebackground: "#FFAA00", statusLine: true, label: 'Pending', },
        { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
      ],

      //======= Action Date Start===== //
      action: [
        { label: 'View', link:"/accounts-details"},
        { label: 'Edit', onClick: onClick },
        { label: 'Delete', onClick: onClick },
      ],
      //======= Action Date End===== //
    },

    {
      cell: [
        { linkText: 'Hikmah Academy of California', linkTextStatus: true, link: '#', },
        { text: 'Hikmah Academy of California', iconStart: <DomainRoundedIcon />, IconStart: true, },
        { linkText: 'Omar Suleiman', linkTextStatus: true, link: '#', },
        { text: 'Online', },
        { text: '21-Jan-2024', },
        { Status: true, background: '#FCE7E9', color: '#E63946', linebackground: "#BA1A1A", statusLine: true, label: 'Blocked', },
        { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
      ],

      //======= Action Date Start===== //
      action: [
        { label: 'View', link:"/accounts-details"},
        { label: 'Edit', onClick: onClick },
        { label: 'Delete', onClick: onClick },
      ],
      //======= Action Date End===== //
    },
  ];

  //===== Table Data End === //



  return <div>
    <Table
      headCells={headCells}
      rows={rows}
      footer={true}
      tableHeader={true}
    />
  </div>
};

export default TeachingAccounts;
