import React, { useEffect, useState } from "react";

import InnerPageLayout from '../../components/InnerPageLayout/InnerPageLayout';

import { RiFileInfoFill } from "react-icons/ri";
import { RiGroupLine } from "react-icons/ri";
import { GrGroup } from "react-icons/gr";
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import TeachingProfile from './TeachingProfile';

const AccountsDetails = () => {


  const breadcrumbsData = [
    {
      label: "Accounts",
      link: "/accounts",
      icon: <MenuBookIcon />,
      //handleClick={handleClick}
    },

    {
      label: "Hikmah Academy of California",
    },

  ];


  const sideBarTabLabel = [
    {
      icon: <RiGroupLine />,
      label: "Teaching Profile",
    },
    {
      icon: <RiGroupLine />,
      label: "Courses",
    },
    {
      icon: <GrGroup />,
      label: "Users",
    },
    {
      icon: <CampaignOutlinedIcon />,
      label: "Payments",
    },
    {
      icon: <CalendarTodayOutlinedIcon />,
      label: "Plan",
    },
    {
      icon: <CalendarTodayOutlinedIcon />,
      label: "Billing History",
    },
    {
      icon: <CalendarTodayOutlinedIcon />,
      label: "Referrals",
    },
  ]

  const sideBarTabBody = [
    {
      id: 0,
      panel: <TeachingProfile />,
    },
    {
      id: 1,
      panel: "dsfsdfdsfsdv",
    },
    {
      id: 2,
      panel: "dsfsddsvsdvsdvsdvf",
    },

  ]


  return <div>

    <InnerPageLayout
      BackArrow={true}
      //BackArrowlink=""
      //BackArrowOnlcikc={BackArrowOnlcikc}
      CloseArrow={true}

      BreadcrumbsStatus={true}
      breadcrumbsData={breadcrumbsData}

      SideBarStatus={true}
      sideBarTabLabel={sideBarTabLabel}
      sideBarTabBody={sideBarTabBody}

    />

  </div>
};

export default AccountsDetails;
